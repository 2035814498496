<template>
  <div class="bg-white p-8  modal-md pb-6">
    <div class="text-xl my-8 mt-4 font-semibold font-merri text-center">
      We are at capacity...
    </div>
    <div>
      <p>
        Unfortunately we are not currently taking any new subscriptions at the
        moment. This will be changing soon.
      </p>

      <div class="mt-8 mb-4">
        If you want to be informed when we do please leave your email address
        below.
      </div>
      <div
        v-if="hasError"
        class="border-2 border-red-400 bg-red-100 p-4 py-3 text-red-400 rounded mb-4 error-msg"
      >
        Oh no... there was an error. Please try again.
      </div>
      <div
        v-if="isSuccess"
        class="border-2 border-green-400 bg-green-100 p-4 py-3 text-green-400 rounded mb-4 error-msg"
      >
        Thanks! We will let you know when you can get your subscription.
      </div>

      <div class="">
        <form @submit.prevent="sendEmail" novalidate="true">
          <input
            v-model="email"
            class="fs-exclude"
            type="text"
            ref="email"
            placeholder="email@example.com"
          />
          <loading-button
            :is-loading="isLoading"
            :is-enabled="formValid"
            :is-full="true"
            class="mt-4"
            label="Sign up for updates"
          />
        </form>
      </div>
      <div class="text-center text-gray-600 mt-6">
        <a @click="closeModal">Cancel</a>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';
import http from '@/http';
export default {
  props: ['data'],
  components: {
    LoadingButton
  },
  computed: {
    formValid: function() {
      if (this.email && this.checkEmail(this.email) && !this.isSuccess) {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      email: '',
      isLoading: false,
      hasError: false,
      errorMessage: '',
      isSuccess: false
    };
  },
  methods: {
    closeModal: function() {
      this.$parent.$emit('make-close');
    },
    checkEmail: function(email) {
      if (email) {
        let regex = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        );
        return email.match(regex);
      }
    },
    hideEmail: function(email) {
      let str = email;
      str = str.split('');
      let finalArr = [];
      let len = str.indexOf('@');
      str.forEach((item, pos) => {
        pos >= 3 && pos <= len - 2
          ? finalArr.push('*')
          : finalArr.push(str[pos]);
      });
      return finalArr.join('');
    },
    sendEmail: function() {
      let data = {
        email: this.email
      };
      http
        .post('/em', data)
        .then(response => {
          this.isSuccess = true;
          console.log(response);

          this.$store.dispatch('sendMpEvent', {
            event: 'Email Captured',
            properties: {
              location: this.$route.name,
              email: this.hideEmail(this.email),
              type: 'EVT'
            }
          });
        })
        .catch(err => {
          this.hasError = true;
          throw Error(err.response.data.error.message);
        });
    }
  },
  mounted() {
    this.$refs.email.focus();
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
